import { ReactComponent as VaniLogo } from "../assets/vani_logo_long.svg";

const Logo = (props: any) => {
  return (
    <VaniLogo
      style={{
        height: props.height,
        width: props.width,
        ...props.style,
      }}
    />
  );
};

export default Logo;
