import {
  MeetingHandler,
  MeetingStartRequest,
  MeetingType,
  VaniEvent,
} from "vani-meeting-client";
import EventEmitter from "events";

class VideoHandler {
  static instance?: VideoHandler;
  meetingRequest?: MeetingStartRequest;
  meetingHandler: MeetingHandler;
  eventEmitter: EventEmitter;
  roomId?: string;

  static getInstance() {
    if (!VideoHandler.instance) {
      VideoHandler.instance = new VideoHandler();
    }
    return VideoHandler.instance;
  }

  constructor() {
    this.meetingHandler = new MeetingHandler();
    this.eventEmitter = new EventEmitter();
    this.getMeetingRequest = this.getMeetingRequest.bind(this);
    this.cleanUp = this.cleanUp.bind(this);
  }

  cleanUp() {
    console.log("Vani CLeanup");
    if (this.meetingHandler) {
      this.getMeetingHandler().endAndDestory();
    }
    this.meetingRequest = undefined;
    this.eventEmitter.removeAllListeners();
    VideoHandler.instance = undefined;
  }

  setup(roomId: string, webSocketUrl: string, userData: any) {
    if (!this.meetingRequest || this.meetingRequest == null) {
      this.meetingRequest = this.getMeetingHandler().meetingStartRequestObject(
        roomId,
        new Date().getTime() + "",
        "VaniMeetingReactApp",
        webSocketUrl
      );
      this.meetingRequest.numberOfUsers = 10;
      this.meetingRequest.userData = userData;
      this.meetingRequest.meetingType = MeetingType.SFU;
      VideoHandler.getInstance()
        .getMeetingHandler()
        .getEventEmitter()
        ?.on(VaniEvent.OnSocketDisconnected, this.cleanUp);
      console.log("this.meetingRequest");
    }
  }

  getMeetingHandler(): MeetingHandler {
    return this.meetingHandler;
  }

  getMeetingRequest() {
    return this.meetingRequest;
  }
}

export default VideoHandler;
