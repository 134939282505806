import React, { Component, Suspense } from "react";
import "../styles/thankyou.css";
import "../styles/permission.css";
import "../styles/call.css";
import LogoHeader from "../components/Logo";
import Logo from "../assets/vani_logo_long.svg";
import exit from "../assets/img/thank.svg";
import SuspenseFallBack from "../components/SuspenseFallBack";
import playStoreImg from "../assets/play.png"
import appStoreImg from "../assets/appstore.png"
import i18n from "../i18n";
import VideoHandler from "../utility/VideoHandler";
interface ThankyouProps {
  history: any;
}
export default class Thankyou extends Component<ThankyouProps, {}> {
  componentDidMount() {    
    console.log(i18n)
    window.addEventListener('popstate', this.handleBackButton);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });

    VideoHandler.getInstance().cleanUp()
  }
  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButton);
    
  }
  handleBackButton(e:any) {
    e.preventDefault();
    // Optionally, you can show a message or take some action here.
  }
  render() {
    return (
      <Suspense fallback={<SuspenseFallBack />}>
        <>
          <div className="call thank">
            <LogoHeader height={25} width={220} />
            <div className="thank-wrap">
              <div className="thank-detail">
                <img src={exit} alt="user" />
              </div>

              <div className="thank-content">
                <p>{i18n.t("Thanks_For_Using_Vani_Meeting")}</p>
                <p>
                {i18n.t("Download_Vani_Meetings")}
                </p>                
                <div className="thank-bottom">
                  <div className="logo small">
                    <img src={Logo} alt="logo " />
                  </div>

                  <div className="bottom-png-container">
                  <img
                  style={{width:"128px"}}
                    className="bottom-png"
                    onClick={() => {
                      window.open("https://apps.apple.com/us/app/vani-meetings-share-screen/id6469126562", "_blank");
                    }}
                    src={appStoreImg}
                    alt="ps"
                  />
                  <img
                    className="bottom-png"
                    onClick={() => {
                      window.open("http://play.google.com/store/apps/details?id=com.vani.meeting", "_blank");
                    }}
                    src={playStoreImg}
                    alt="ps"
                  />
                  
                    </div>  
                  
                </div>
              </div>
            </div>
          </div>
        </>
      </Suspense>
    );
  }
}
