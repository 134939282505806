import { Component, Suspense } from "react";
import ReactGA from "react-ga";
// import "../../../styles/detail.css";
import "../../../styles/permission.css";
import "../../../styles/call.css";
import { browserName, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Utility from "../../../utility/Utility";
import React from "react";
import copy from "copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import '../../../index.css';

const UnsupportedView = React.lazy(() => import("../view/UnsupportedView"));
interface Props {
  string: any;
}
interface State {
  cantNotRedirectToIosBrowser: boolean;
}
export class UnSupportedWrap extends Component<Props, State> {
  intent: any;
  constructor(props: any) {
    super(props);
    this.state = {
      cantNotRedirectToIosBrowser: isIOS ? true : false,
    };
    this.intent = null;
  }
  componentDidMount() {
    if (isIOS) {
      this.setState({ cantNotRedirectToIosBrowser: true });
    } else {
      this.handleRedirection();
    }
  }

  onBrowserButtonClicked = (browser: string) => {
    var url = window.location.origin;
    url = url + "/?vm=" + Utility.getRoomId() + "&zn=" + Utility.getZoneId() +"&v=1";
    console.log(url);
    // url = url.replace("https", "");
    console.log("url url url url", url);
    const el = document.createElement("a");
    switch (browser) {
      case "chrome":
        url = window.location.host + "/?vm=" + Utility.getRoomId() + "&zn=" + Utility.getZoneId() +"&v=1";
        this.intent = `googlechrome://${url}`;
        console.log("Chrome button clicked");
        break;
      case "firefox":
        this.intent = `firefox://open-url?url=${url}`;
        // this.intent = "firefox://open-url?url=https://google.com"
        console.log("Firefox button clicked");
        break;
      case "opera":
        this.intent = `touch-${url}`;
        console.log("Opera button clicked");
        break;
      case "edge":
        url = url.replace("https://", "");
        this.intent = `microsoft-edge://${url}`;
        console.log("Edge button clicked");
        break;
      case "safari":
        this.intent = `safari://${url}`;
        console.log("Safari button clicked");
        break;
      default:
        url = url.replace("https", "");
        this.intent = `intent://${url}#Intent;scheme=https;package=com.android.chrome;end;`;
        console.log("Unknown browser button clicked");
    }

    el.href = this.intent;
    el.click();
  };

  handleCopyLink = () => {
    copy(window.location.host  + "/?vm=" + Utility.getRoomId() + "&zn=" + Utility.getZoneId() +"&v=1") 
    const msg = this.props.string("link_copied");
    toast.success(msg);
  };
  handleRedirection = () => {
    var url = window.location.origin;
    url = url  + "/?vm=" + Utility.getRoomId() + "&zn=" + Utility.getZoneId()  +"&v=1";
    console.log(url);

    url = url.replace("https", "");
    console.log(url);

    const el = document.createElement("a");
    console.log(el);
    this.intent =
      "intent" +
      url +
      "#Intent;scheme=" +
      "https" +
      ";package=com.android.chrome;end;";
    el.href = this.intent;

    el.click();
  };
  render() {
    return <UnsupportedView controller={this} />;
  }
}

const UnSupported = () => {
  const { t } = useTranslation();
  return <UnSupportedWrap string={t} />;
};

export default UnSupported;
