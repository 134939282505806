import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Suspense, useEffect } from "react";
import React from "react";
import SuspenseFallBack from "./components/SuspenseFallBack";
import {
  isAndroid,
  isChrome,
  isDesktop,
  isEdge,
  isFirefox,
  isIOS,
  isMacOs,
  isOpera,
  isSafari,
  isSamsungBrowser,
  isWindows,
} from "react-device-detect";
import UnSupported from "./pages/unsupported/component/UnsupportedComponent";
import Thankyou from "./pages/Thankyou";
import Utility from "./utility/Utility";
import Comp404 from "./pages/404";
const Permission = React.lazy(() => import("./pages/Permission"));
const JoinForm = React.lazy(() => import("./pages/JoinForm"));
const Call = React.lazy(() => import("./pages/Call"));



function App() {  
  useEffect(()=>{
    // if(window.location.hostname)
    const currentUrl = window.location.href;
    const pageName = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
    console.log('Current URL:', currentUrl);
    if(pageName=="thank-you"){
      return;
    }
    else{
      if (!Utility.getRoomId() || !Utility.getZoneId()) {                            
        window.location.href="/thank-you" 
       //  this.props.history.replace("/thank-you")
       }
    }    
  })
  // if(true){
  //   return (
  //     <Suspense fallback={<SuspenseFallBack />}>
  //         <UnSupported />
  //       </Suspense>
  //   )
  // }
  if (isAndroid || isWindows || isMacOs || isDesktop ||isEdge) {
    if (
      isDesktop ||
      isChrome ||
      isSafari ||
      isFirefox ||
      isSamsungBrowser ||
      isOpera ||
      isEdge
    ) {
    return (
      <BrowserRouter>
        <Switch>
          <Suspense fallback={<SuspenseFallBack />}>
            <Route path={"/"} component={Permission} exact />
            <Route path={"/join"} component={JoinForm} exact />
            <Route path={"/screen"} component={Call} exact />
            <Route path={"/thank-you"} component={Thankyou} exact />
            {/* <Route path={"*"} component={Comp404} exact /> */}
          </Suspense>
        </Switch>
      </BrowserRouter>
    );
    }
    else{
      return (
        <>
          <Suspense fallback={<SuspenseFallBack />}>
            <UnSupported />
          </Suspense>
        </>
      );
    }
  } 
else if (isIOS) {
  if (isChrome || isSafari || isOpera || isFirefox || isEdge) {
    return (
      <BrowserRouter>
        <Switch>
          <Suspense fallback={<SuspenseFallBack />}>
            <Route path={"/"} component={Permission} exact />
            <Route path={"/join"} component={JoinForm} exact />
            <Route path={"/screen"} component={Call} exact />
            <Route path={"/thank-you"} component={Thankyou} exact />
            {/* <Route path={"*"} component={Comp404} exact /> */}
          </Suspense>
        </Switch>
      </BrowserRouter>
    ); 
  }
  else{
    return (
      <>
        <Suspense fallback={<SuspenseFallBack />}>
          <UnSupported />
        </Suspense>
      </>
    );
  }
}
  else {
    return (
      <>
        <Suspense fallback={<SuspenseFallBack />}>
          <UnSupported />
        </Suspense>
      </>
    );
  }
}

export default App;
