import VideoHandler from "./VideoHandler";

const queryString = require("query-string");

class Utility {
  static getRoomId(): string | undefined {
    const queryParmas = queryString.parse(window.location.search);
    if (queryParmas.vm && queryParmas.vm !== null) {
      return queryParmas.vm.toLowerCase();
    }
    return undefined;
  }
  static getZoneId(): string | undefined {
    const queryParmas = queryString.parse(window.location.search);
    if (queryParmas.zn && queryParmas.zn !== null) {
      return queryParmas.zn.toLowerCase() as string;
    }
    return undefined;
  }
  static getAppType(): string | undefined {
    const queryParmas = queryString.parse(window.location.search);
    if (queryParmas.v && queryParmas.v !== null) {
      return queryParmas.v.toLowerCase() as string;
    }
    return undefined;
  }
  static getWssUrl = (params: string, appVersion: string) => {
    if (appVersion && appVersion.toLowerCase() == "1") {
      if (params == "bl") {
        return "wss://ind.socket.doorvi.co:4031/?connection=";
      } else if (params === "ny") {
        return "wss://us.socket.doorvi.co:4031";
      } else {
        return "wss://eu.socket.doorvi.co:4031";
      }
    } else {
      if (params == "bl") {
        return "wss://socket.doorvi.co/?connection=";
      } else if (params === "ny") {
        return "wss://socket.nyc.doorvi.co:4031";
      } else {
        return "wss://socket.frank.doorvi.co:4031";
      }
    }
  };
  static getRoomIdOrRedirect = () => {
    console.log("getRoomIdOrRedirect", Utility.getRoomId());
    const roomId = Utility.getRoomId();
    if (roomId) {
      return roomId;
    }
    return undefined;
  };

  static checkMeetingRequestAndRedirect = (): boolean => {
    if (VideoHandler.getInstance().meetingRequest === undefined) {
      window.location.replace("/?vm=" + Utility.getRoomId());
      return false;
    }
    return true;
  };
}

export default Utility;
