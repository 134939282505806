import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import hin from "./locales/hin/translation.json";
import en from "./locales/en/translation.json";
import ru from "./locales/ru/translation.json";
import fre from "./locales/fre/translation.json";
import es from "./locales/es/translation.json";
import pt from "./locales/brazil/translation.json";

const userLanguage = window.navigator.language || "en";
i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    debug: true,
    lng: userLanguage,
    fallbackLng: userLanguage, // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    resources: {
      en: {
        translations: en,
      },
      hi: {
        translations: hin,
      },
      pt: {
        translations: pt,
      },
      ru: {
        translations: ru,
      },
      fr: {
        translations: fre,
      },
      es: {
        translations: es,
      },
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;
